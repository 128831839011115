body {
  margin: 0;
  /* font-family: 'Sora', sans-serif; */
  font-family: 'Nunito', sans-serif;
  padding-top: 15px; 
}



/* .main-content {
  margin-left: 250px; 
  padding: 20px; 
  background-color: #f0f2f5; 
  min-height: 100vh; 
  border-radius: 8px; 
  pading etait commenté
} */

.main-content {
 
  display: flex; 
  justify-content: start;
  align-items: start; 
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh; 
  border-radius: 8px;
  box-sizing: border-box; 
}


@media (max-width: 768px) {
  .sidebar {
      display: none; 
  }

  .main-content {
      margin-left: 0; 
  }
}
