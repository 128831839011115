.menu-card {
    position: relative;
    margin-bottom: 20px; 
  }
  

  /* @media (min-width: 768px) {
    .menu-card {
      position: fixed;
      top: 0px; 
      left: 20px;
      width: 300px; 
    }
  } */
  
  @media (min-width: 1024px) {
    
  }
  