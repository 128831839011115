.card {
    font-family: 'Nunito', sans-serif;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px !important;
    box-shadow: 0 0 10px #0000001a;
    background-color: #fff;
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }


  .alert-success {
    color: #4ccf13;
    background-color: #003366;
    border-color: #c3e6cb;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
.card h2 {
    margin-bottom: 20px;
    text-align: center;
}
.card input {
    margin-bottom: 15px;
}
.text-danger{
    color: red !important;
}
.card .divider {
    height: 1px;
    background-color: #ddd;
    margin: 20px 0;
}
.card button {
    /* width: 100%; */
    background-color: #003366;
    color: white;
}
.card button:hover {
    background-color: #00cc44;
    border: 1px solid #00cc44;
}

.intl-tel-input{
    width: 100%;
    margin-bottom: 15px;
}

.country-list{
    width: 360px;
}


.react-tel-input .form-control {

    width: 100% !important;
    height: 40px !important;

}

.form-control {
    background-color: #f5f5f5 !important;
    border: 1px solid #d3d3d3 !important;
    padding: 10px;
}
    