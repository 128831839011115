/* ✅ Conteneur de la liste des contacts */
.contacts-container {
    background: white;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* ✅ Titre "Messagerie" */
  .contacts-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* ✅ Style de chaque contact */
  .contact-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  /* ✅ Contact sélectionné */
  .contact-item.selected {
    background: #002f6c; /* Bleu foncé */
    color: white;
  }
  
  /* ✅ Avatar circulaire */
  .contact-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  /* ✅ Infos du contact */
  .contact-info {
    flex: 1;
  }
  
  /* ✅ Nom du contact */
  .contact-name {
    font-size: 14px;
    font-weight: bold;
  }
  
  /* ✅ Dernier message en gris */
  .contact-last-message {
    font-size: 12px;
    color: #777;
    margin: 0;
  }
  
  .card-body{
    background: #fff !important;
  }

  .bg-chat{
    background: #002f6c;
  }

  .card input {
    margin-bottom: 0px;
}

.chat-input{
    background-color: #f5f5f5 !important;
    border: 1px solid #002f6c !important;
    padding: 18px !important;
    border-radius: 20px !important;
}

.chat-button{
    background: #002f6c;
    padding: 12px 15px;
    color: #fff;
    border-radius: 50%;
}