.sidebar {
    font-family: "Nunito"; 
    font-Weight: "500";
    font-style: "normal";
    width: 200px;
    position: fixed;
    top: 80px;
    left: 20px;
    border-radius: 10px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.sidebar nav ul {
    list-style-type: none;
}

.sidebar nav ul li {
    margin-bottom: 5px;
}

.sidebar nav ul li a {
    text-decoration: none;
    color: #003366;
    font-size: 16px;
    display: block;
    padding: 10px 15px;
}

.sidebar nav ul li a i {
    margin-right: 10px;
    font-size: 18px;
}

.sidebar nav ul li a:hover {
    background-color: #f0f0f0;
    /* border-radius: 5px; */
    width: 100%;
}


/* ✅ Style du menu actif */
.sidebar nav ul li a.active {
    background-color: #002f6c; /* Bleu foncé */
    color: white;
    font-weight: bold;
    /* border-radius: 5px; */
}

/* ul li i{
    font-weight: bold;
    font-size: 25px;
} */