.button-buy {
    background: #00cc44;
    color: #fff;
    border-radius: 6px;
    padding: 2px 6px;
}

.button-sell {
    background: #ff2705;
    color: #fff;
    border-radius: 6px;
    padding: 2px 6px;
}


.responsive-image {
    width: 250px;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  @media (max-width: 768px) {
    .responsive-image {
      width: 200px;
      height: 100%;
    }
  }
  