.custom-caption {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    /* background: rgba(0, 0, 0, 0.5); */
    padding: 20px;
    border-radius: 10px;
    width: 80%;
   
  }
  
  .carousel-caption{
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 50% !important;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
  .custom-caption h2 {
    margin: 0;
    font-size: 38px;
  }
  
  .custom-caption span {
    color: #00cc44;
    font-weight: bold;
  
  }
  