.formation-container {
    padding: 20px;
  }
  
  .video-card {
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .video-card:hover {
    transform: scale(1.03);
  }
  
  .video-title {
    font-size: 16px;
    font-weight: bold;
    color: #002f6c;
  }
  
  .video-description {
    font-size: 14px;
    color: #555;
  }

  .card{
         margin: 0 auto;
  }